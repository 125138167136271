import { ReactNode } from 'react';

import ACFSignup from 'types/ACFSignup';

import SchedulingSignup from 'components/modal/signups';
import WistiaVideo from 'components/video/wistia-player';

import './styles/landing.scss';
import DefaultLandingHero from './partial/default-landing-hero';

type HeroLandingProps = {
  children: ReactNode
  defaultLandingHero: any
  launch_modal_button_text: string,
  modal_submit_button_text: string,
  modal_title: string,
  signup: ACFSignup,
  title: string,
  uniqueId: string
};

export const HeroLanding = ({
  title,
  modal_title,
  uniqueId,
  signup,
  defaultLandingHero,
  launch_modal_button_text,
  modal_submit_button_text,
  children,
}:HeroLandingProps) => {

  function renderDefaultLandingHero() {
    if (!defaultLandingHero) {
      return null;
    }

    if (defaultLandingHero.wistia_id) {
      return <WistiaVideo hashedId={ defaultLandingHero?.wistia_id } className="video-player has-shadow-large" />;
    }

    return <DefaultLandingHero { ...defaultLandingHero } />;
  }

  return (
    <section
      className="section landing-hero-section"
      data-section="landing page hero"
    >
      <div className="container">
        <h1 className="headline has-text-centered">{ title }</h1>
        <div className="signup-cta-container">
          <SchedulingSignup
            uniqueId={ uniqueId }
            modalTitle={ modal_title }
            showInitFields={ true }
            buttonInline
            legalText={ signup.legal_text }
            fieldLayout="row"
            signupModalLaunch={ launch_modal_button_text }
            signupModalSubmit={ modal_submit_button_text }
          />
        </div>
        { renderDefaultLandingHero() }
        { children }
      </div>
    </section>
  ); 
};